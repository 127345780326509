import { defineStore, storeToRefs } from "pinia"
import * as amplitude from "@amplitude/analytics-browser"
import { type BrowserOptions } from "@amplitude/analytics-types"
import { useMeStore } from "./me"
import { watch } from "vue"
import { useConfigStore } from "./configuration"
import * as Sentry from "@sentry/vue"; // todo, refactor out, we need this quickly



export const useAmplitudeStore = defineStore("amplitude_store", () => {

    const me_store = useMeStore();
    const { me } = storeToRefs(me_store);

    const browser_options: BrowserOptions = {
        serverZone: 'EU',
        transport: 'xhr',
        trackingOptions: {
            ipAddress: false
        },
        defaultTracking: {
            pageViews: false,
            sessions: true,
            attribution: true,
            fileDownloads: false,
            formInteractions: false
        },
        identityStorage: 'sessionStorage'
    }

    watch(me, () => {
        init();
    });





    const init = () => {
        const config_store = useConfigStore();
        const { configuration } = storeToRefs(config_store);
        const amplitude_key = configuration.value?.amplitude_api_key;
        const user_id = me.value?.id!;
        identify();
        amplitude.init(amplitude_key!, user_id, browser_options)
    }

    const firstname_lastnameinitial = (full_name: string) => {
        if (!full_name) 
            return ('');
        const name = full_name.split(' ');
        const first_name = name[0];
        const last_name = name[name.length - 1];
        const initial = last_name.charAt(0) + '.';
        return first_name + ' ' + initial;
    }





    const identify = () => {
        const identifyEvent = new amplitude.Identify();
        if (!me.value) {
            amplitude.identify(identifyEvent);
            return
        }

        const workspace_id = me.value?.default_workspace_id!;
        const full_name = me.value?.name!;
        const amplitude_name = firstname_lastnameinitial(full_name);
        identifyEvent.set('workspace_id', workspace_id);
        identifyEvent.set('user_name', amplitude_name);
        amplitude.identify(identifyEvent);

        Sentry.setUser({
            id: me.value?.id!,
            username: me.value?.email!,
            email: me.value?.email!,
            workspace_id: me.value?.default_workspace_id,
            name: amplitude_name
        });
    }

    const track = (event_type: string, event_properties: any) => {
        const workspace_id = me.value?.default_workspace_id;
        event_properties = { ...event_properties, workspace_id }
        amplitude.track(event_type, event_properties)
    }
    return { track, init }
})