<template>
    <div class="sidebarwrapper">
        <div class="sidebar">
            <div class="top">
                <LogoSidebar />
                <div class="patient-section">
                    <div class="menu">
                        <div class="new">
                            <DefaultButton class="button" icon-position="Left" @click="new_context" :size="'md'">
                                <template #icon>
                                    <AddIcon />
                                </template>
                                <span style="padding-top: 2px;">Ny konsultasjon</span>
                            </DefaultButton>
                            <div class="patient-id">
                                <span class="pre-title">Aktiv konsultasjon</span>
                                <input class="description" v-model="title" @keyup.enter="save_title" />
                            </div>
                            <div class="button-group">
                                <SidebarButton title="Chat" :show_button="false" :is_active="active_element === 'chat'"
                                    @click="chat()">
                                    <template #icon>
                                        <ChatIcon />
                                    </template>
                                </SidebarButton>
                                <SidebarTTN />
                                <SidebarButton title="Assistenter" :show_button="false"
                                    :is_active="active_element === 'assistants'" @click="tools()">
                                    <template #icon>
                                        <MedicalAgentIconSidebar />
                                    </template>
                                </SidebarButton>
                            </div>
                        </div>
                        <div class="history">
                            <span class="pre-title">Historiske konsultasjoner</span>
                            <div class="links">
                                <div class="entries">
                                    <span v-for="context in top_2_recent_contexts" :key="context.id" class="entry"
                                        @click="go_item(context.id!)">
                                        {{ context.name }}
                                    </span>
                                </div>
                                <div class="tooltip">
                                    <span class="more" @click="history_open = !history_open">Vis hele historikken
                                        ({{ contexts.length }})</span>
                                    <SlideInBox :visible="history_open" title="Historikk" @close="history_open = false">
                                        <template v-slot:content>
                                            <History @close="history_open = false" />
                                        </template>
                                    </SlideInBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="help-wrapper" id="intercom_custom_launcher" ref="intercom_ref">
                    <span class="title-wrapper">
                        <QuestionIcon />
                        <span class="text">Trenger du hjelp?</span>
                    </span>
                </div>
                <div class="user-wrapper">
                    <div class="thumbnail">
                        <span class="text">{{ initials }}</span>
                    </div>
                    <div class="user">
                        <span class="name">{{ shortened_name }}</span>
                        <span class="logout" @click="sign_out">Logg ut</span>
                    </div>
                </div>
                <span class="version">
                    {{ configuration?.version_info?.name }} {{ configuration?.version_info?.numbered }}
                </span>
            </div>
        </div>
        <div class="outline"></div>
    </div>
</template>

<script setup lang="ts">
import LogoSidebar from '@/components/icons/LogoSidebar.vue';
import AddIcon from '@/components/icons/AddIcon.vue';
import { useMeStore } from '@/stores/me';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import History from '@/components/History/History.vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import DefaultButton from '@/components/DefaultButton.vue';
import SidebarButton from '@/components/Sidebar/SidebarButton.vue';
import ChatIcon from '@/components/icons/ChatIcon.vue';
import SlideInBox from '@/components/SlideInBox.vue';
import { useMyContextsStore } from '@/stores/contexts';
import { useContextStore } from '@/stores/context';
import QuestionIcon from '@/components/icons/QuestionIcon.vue';
import { useIntercomStore } from '@/stores/intercom';
import { DateTime } from 'luxon';
import { useAmplitudeStore } from '@/stores/amplitude';
import SidebarTTN from './Sidebar/SidebarTTN.vue';
import { useConfigStore } from '@/stores/configuration';
import MedicalAgentIconSidebar from '@/components/icons/MedicalAgentIconSidebar.vue';


const config_store = useConfigStore();
const { configuration } = storeToRefs(config_store);
const route = useRoute();
const amplitude = useAmplitudeStore();
const history_open = ref<boolean>(false);
const intercom = useIntercomStore();
const intercom_ref = ref<HTMLElement | null>(null);


const context_store = useContextStore();
const { context, is_recording } = storeToRefs(context_store);

let confirm_leave_handler: (event: BeforeUnloadEvent) => void;
onMounted(() => {
    intercom.init();
    confirm_leave_handler = (event: BeforeUnloadEvent) => {
        if (is_recording.value) {
            event.preventDefault();
            return "Du er i ferd med å forlate siden mens du er midt i et opptak. Er du sikker på at du vil forlate siden?";
        }
    };
    window.addEventListener('beforeunload', confirm_leave_handler);
});

onUnmounted(() => {
    if (confirm_leave_handler) {
        window.removeEventListener('beforeunload', confirm_leave_handler);
    }
});


const router = useRouter();
const new_context = () => {
    if (is_recording.value) {
        amplitude.track('Ny Konsultasjon Clicked', { error: 'Recording in progress' });
        window.alert("Du kan ikke starte en ny konsultasjon mens du er i et opptak. Avslutt opptaket først.");
        return;
    }

    amplitude.track('Ny Konsultasjon Clicked', {});
    router.push({ name: 'home', force: true });
};

const me_store = useMeStore();
const { me } = storeToRefs(me_store);
const contexts_store = useMyContextsStore();
const { contexts } = storeToRefs(contexts_store);

const top_2_recent_contexts = computed(() => {
    return contexts.value.slice(0, 2);
});


const chat = () => {
    const id = context.value?.id;
    router.push({ name: 'chat', params: { context_id: id } });
};

const tools = () => {
    const id = context.value?.id;
    amplitude.track('Sidebar Item Clicked', { context_id: id, item: 'Assistants' });
    router.push({ name: 'user.assistants', params: { context_id: id } });
};


const initials = computed(() => {
    const name_parts = me?.value?.name.split(' ');
    if (!name_parts) {
        return '';
    }
    const first_initial = name_parts[0][0].toUpperCase();
    const last_initial = name_parts[name_parts.length - 1][0].toUpperCase();
    return `${first_initial}${last_initial}`;
});

const shortened_name = computed(() => {
    const name_parts = me?.value?.name.split(' ');
    if (!name_parts) {
        return '';
    }
    const first_initials = name_parts.slice(0, -1).map(part => part[0].toUpperCase()).join('.');
    const last_part = name_parts[name_parts.length - 1];
    return `${first_initials}. ${last_part}`;
});



const go_item = (id: string) => {
    const go_context = contexts.value.find(c => c.id === id);
    const has_any_thread = go_context?.threads.length;
    const has_ttn = go_context?.agent_executions.find(ae => ae.namespace === "maiamd.mymaia.agents.ambient-journal-note");

    if (has_any_thread) {
        router.push({ name: 'chat', params: { context_id: id } });
    }

    else if (has_ttn) {
        router.push({ name: 'ambient', params: { context_id: id } });
    }
    else {
        router.push({ name: 'chat', params: { context_id: id } });
    }
};
const sign_out = () => {
    window.location.href = '/account/logout';
}

const title = computed({
    get() {
        const t = context.value?.name!;
        if (t === "Ingen tittel") {
            const created_at = context.value?.created_at!;
            const time = DateTime.fromISO(created_at).toLocal().setLocale('nb').toFormat("HH:mm");
            return `${time} - ${t}`;
        }
        return t;
    },
    set(value: string) {
        context.value!.name = value;
    }
});

const save_title = async (event: Event | undefined | null) => {
    if (event) {
        (event.target as HTMLInputElement).blur();
    }
    await context_store.update({ name: title.value! });
}

const active_element = computed(() => {
    if (route.name === 'chat') {
        return 'chat';
    }
    if (route.name === 'ambient') {
        return 'voice';
    }

    if (route.name === 'user.assistants') {
        return 'assistants';
    }
    return '';
});

watch(() => intercom_ref.value, () => {
    if (intercom_ref.value) {
        intercom.init();
    }
});


</script>

<style lang="scss" scoped>
input {
    color: var(--Text-Title, #151515);
    font-family: Graphik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    border: 2px solid transparent;
    border-radius: 8px;


    &:hover,
    &:focus {
        border: 2px solid var(--Primary-30, #BDCDC5);
    }
}


.sidebarwrapper {
    display: flex;
    width: 260px;
    height: 100vh;
    align-items: center;
    flex-shrink: 0;
    background: var(--Text-White, #FFF);
}

.sidebar {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    align-self: stretch;

}

.top .patient-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
}

.top .patient-section .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .new {
        display: flex;
        padding: 16px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        border-radius: 4px;
        border: 1px solid var(--Greyscale-40, #F1F1F1);
        background: var(--Greyscale-10, #FEFEFE);
        box-shadow: 0px 12px 10px -10px rgba(0, 40, 87, 0.05);




        .patient-id {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            .pre-title {
                align-self: stretch;
                color: var(--Text-Caption, #595959);


                font-family: Graphik;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }

            .description {
                align-self: stretch;
                color: var(--Primary-100, #22593D);
                font-family: Graphik;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }
        }

        .button-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
        }
    }

    .divider {
        height: 1px;
        align-self: stretch;
        background: var(--Greyscale-40, #F1F1F1);
    }

    .history {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;


        .pre-title {
            align-self: stretch;
            color: var(--Text-Caption, #595959);
            font-family: Graphik;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        .links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            align-self: stretch;

            .more {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: #000;
                text-overflow: ellipsis;
                font-family: Graphik;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-decoration-line: underline;
                cursor: pointer;
            }

            .entries {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                .entry {
                    align-self: stretch;

                    color: var(--Text-Title, #151515);
                    font-family: Graphik;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;

                    text-decoration-line: underline;

                    overflow: hidden;
                    word-wrap: break-word;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;


                    cursor: pointer;
                }
            }
        }
    }
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;



    .version {
        color: var(--Text-Caption, #595959);
        font-family: Graphik;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }



    .user-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        .thumbnail {
            display: flex;
            width: 48px;
            height: 48px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            border-radius: 24px;
            background: var(--Primary-5, #F4F7F5);

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 0 0;
                align-self: stretch;

                color: var(--Primary-160, #0E2418);
                text-align: center;
                font-family: Graphik;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: 1.6px;

                user-select: none;
                pointer-events: none;
            }
        }

        .user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;


            .name {
                display: flex;
                width: 111px;
                height: 18px;
                flex-direction: column;
                justify-content: center;

                color: var(--Primary-130, #1A432E);
                font-family: Graphik;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                user-select: none;
                pointer-events: none;
            }

            .logout {
                align-self: stretch;
                color: var(--Secondary-Red-100, #A10404);
                font-family: Graphik;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }
}

.outline {
    width: 1px;
    height: 100%;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--Primary-15, #DEE6E2);
    mix-blend-mode: darken;
}


.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: var(--Primary-30, #BDCDC5);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;


    color: var(--Primary-160, #0E2418);
    font-family: Graphik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;


    position: absolute;
    top: 20%;
    left: 125%;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--Primary-30, #BDCDC5) transparent transparent;
}


.button {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;

    color: var(--Text-White, #FFF);
    font-family: Graphik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.help-wrapper {
    display: flex;
    flex-direction: column;
    width: 211px;
    padding: 8px 8px 8px 12px;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .title-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        .text {
            flex: 1 0 0;
            color: var(--Text-Caption, #595959);
            font-family: Graphik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
    }
}
</style>