import axios from "axios";
import { DateTime } from "luxon";

export interface StreamingAmbientToken {
    token: string;
    expires_at: string;
    created_at: string;
    expires_in_seconds: number;
}

export class StreamingTranscriberTokenProvider {
    private ambience_id: string;
    private _token: StreamingAmbientToken | null = null;

    public constructor(ambience_id: string) {
        this.ambience_id = ambience_id;
    }

    private endpoint = () => `/api/v2/ambients/${this.ambience_id}/token`;

    public token = async (): Promise<StreamingAmbientToken> => {
        if (this._token === null) {
            await this.fetch_token();
        }

        const expiration_utc = DateTime.fromISO(this._token!.expires_at, { zone: "utc" });
        const now_utc = DateTime.utc();
        const diff = expiration_utc.diff(now_utc, "seconds").seconds;
        if (diff <= 60) {
            await this.fetch_token();
        }
        return this._token!;
    };

    private fetch_token = async (): Promise<void> => {
        const response = await axios.post(this.endpoint());
        const data: StreamingAmbientToken = response.data;
        this._token = data;
    }
};