import { defineStore } from "pinia";
import { ref } from "vue";
import { type User } from "@/types/User";
import axios from "@/utils/axios";

export const useMeStore = defineStore("me_store", () => {
    const me = ref<User | null>();
    const endpoint = "/api/v1/me";
    const is_authenticated = ref<boolean>(false);


    const fetch_me = async () => {
        try {
            const response = await axios.get(endpoint);
            me.value = response.data;
            is_authenticated.value = true;

        } catch (error) {
            is_authenticated.value = false;
        }
    }

    const update_me = async (data: { email: string, workplace: string, profession: string }) => {
        const response = await axios.put(endpoint, data);
        me.value = response.data;
    }

    return {
        is_authenticated,
        me,
        update_me,
        fetch_me
    }
});