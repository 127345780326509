<template>
    <SidebarButton title="Tale-til-notat" :show_button="true" :is_active="active_element === 'ambient'" @click="tts()"
        class="container">
        <template #icon>
            <VoiceAIIcon />
        </template>
        <template #button>
            <DefaultButton class="cta" :theme="'Error'" v-if="is_recording"
                @click.stop="stop_recording">
                <span>Stopp</span>
            </DefaultButton>
        </template>
    </SidebarButton>
    <GeneratingNoteStatusMinor :generating="generating" :context_id="context?.id!" />
</template>

<script setup lang="ts">
import { useAmbientJournalNoteStore } from '@/stores/agents/ambientjournalnote';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import SidebarButton from '@/components/Sidebar/SidebarButton.vue';
import VoiceAIIcon from '@/components/icons/VoiceAIIcon.vue';
import GeneratingNoteStatusMinor from '@/views/CopilotContext/AmbientListening/GeneratingNoteStatusMinor.vue';
import { useAmbienceStore } from '@/stores/ambience';
import { useContextStore } from '@/stores/context';
import { useAmplitudeStore } from '@/stores/amplitude';
import DefaultButton from '@/components/DefaultButton.vue';

const router = useRouter();
const journal_agent = useAmbientJournalNoteStore();
const ambience_store = useAmbienceStore();
const context_store = useContextStore();
const { context } = storeToRefs(context_store);
const { output } = storeToRefs(journal_agent);
const { is_recording, ambience_id } = storeToRefs(ambience_store);
const amplitude = useAmplitudeStore();



const generating = computed(() => {
    if (!output.value)
        return false;
    if (output.value.status === 'Completed')
        return false;
    return true;
});

const context_id = computed(() => {
    return output.value?.context_id;
});

const tts = () => {
    router.push({
        name: 'ambient',
        params: {
            context_id: context_id.value
        }
    });
};

const active_element = computed(() => {
    return router.currentRoute.value.name;
});


const stop_recording = async () => {
    await ambience_store.stop();
    await journal_agent.generate(ambience_id.value!, null);
    amplitude.track('Recording Stopped', {
        workspace_id: context.value?.workspace_id,
        context_id: context.value?.id,
        ambience_id: ambience_id.value,
        duration_seconds: 0,
        source: 'Sidebar',
    });
};

</script>

<style lang="scss" scoped>
.container {
    width: 100%;
}

.cta {
    display: flex;
    padding: 2px 8px;
    gap: 8px;
    align-items: center;
    justify-content: center;

    color: var(--Text-White, #FFF);
    font-family: Graphik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
</style>
