import Intercom, { update as IntercomUpdate, show as IntercomShow } from "@intercom/messenger-js-sdk";
import { defineStore, storeToRefs } from "pinia";
import { useMeStore } from "./me";
import { DateTime } from "luxon";
import { watch } from "vue";

export const useIntercomStore = defineStore("intercom_store", () => {
    const user_store = useMeStore();
    const { me } = storeToRefs(user_store);
    const init = () => {
        
        const created_at = me.value?.created_at ? DateTime.fromISO(me.value.created_at).toMillis() / 1000 : 0;
        const params = {
            app_id: 'ti4vo16z',
            app_name: 'MaiaMD Copilot',
            alignment: 'right',
            vertical_padding: 120,
            hide_default_launcher: true,
            custom_launcher_selector: "#intercom_custom_launcher"
        }
        const logged_in_params = {
            ...params,
            user_id: me.value?.id ?? '',
            name: me.value?.name ?? '',
            created_at: created_at
        }

        if (me.value) {
            Intercom(logged_in_params);
        }
        else {
            Intercom(params);
        }
    }

    const update = () => {
        IntercomUpdate({
            user_id: me.value?.id ?? '',
            name: me.value?.name ?? '',
        });
    };

    watch(me, () => {
        if (me.value) {
            update();
        }

    });


    return {
        init
    }
});