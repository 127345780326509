import axios, { type AxiosRequestConfig } from 'axios';
import router from '@/router/index';


interface RedirectUnauthorizedConfiguration extends AxiosRequestConfig {
    redirectOnUnauthorized?: boolean;
}


const instance = axios.create({});
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const config = error.config as RedirectUnauthorizedConfiguration;
        if (config.redirectOnUnauthorized === false) {
            return Promise.reject(error);
        }
        
        if (error.response.status === 401) {
            router.push({ name: 'login' });
        }
        return Promise.reject(error);
    }
);


instance.interceptors.request.use((config) => {
    const xsrfToken = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith('XSRF-TOKEN'))
        ?.split('=')[1];

    if (xsrfToken) {
        config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }
    return config;
});


export default instance;