<template>
    <div class="container123" v-if="generating">
        <div class="icon">
            <span class="loader" />
        </div>
        <div class="text-wrapper">
            <span class="title">Notat skrives</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

const props = defineProps<{
    context_id: string;
    generating: boolean;
}>();

const router = useRouter();
const go = () => {
    router.push({
        name: 'ambient',
        params: {
            context_id: props.context_id
        }
    });
};


</script>

<style lang="scss" scoped>
.container123 {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.icon {
    width: 30px;
    height: 30px;
}

.text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
}



.title {
    color: var(--Text-Caption, #595959);
    font-family: Graphik;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.subtitle {
    color: var(--Primary-130, #1A432E);
    font-family: Graphik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
    color: #84700E;
    font-size: 4px;
    position: absolute;
    text-indent: -9999em;
    transform: translateZ(0);
    transform: translateX(10px);
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    left: -2.5em;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }

    40% {
        box-shadow: 0 2.5em 0 0
    }
}
</style>