<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="26" viewBox="0 0 104 26" fill="none">
        <path
            d="M3.41806 19.6371C3.41806 23.038 4.19329 24.5738 6.06089 25.5246V25.5977H0V25.5246C1.5857 24.5373 2.32569 23.0014 2.32569 20.3319V4.38819C2.32569 1.97468 2.04379 1.06048 0.599042 0.0731377V0H9.12658L14.2713 14.7736L19.2398 0H27.3445V0.0731377C25.8645 1.06048 25.5826 1.97468 25.5826 4.38819V21.173C25.5826 23.5865 26.0055 24.5373 27.4502 25.5246V25.5977H17.2665V25.5246C18.7465 24.5373 19.1693 23.5865 19.1693 21.173V3.29114L11.558 26H11.417L3.41806 3.25457V19.6371Z"
            fill="#22593D" />
        <path
            d="M46.9003 24.0985V24.1716C46.1603 25.1955 44.6099 25.9269 42.9537 25.9269C40.4518 25.9269 39.1128 24.7567 38.7252 22.8186C38.7252 22.7454 38.6547 22.1603 38.6547 22.0506C38.1261 24.3544 36.2938 25.8537 33.6509 25.8537C31.0786 25.8537 28.9291 24.3544 28.9291 21.3558C28.9291 17.3699 32.4881 16.4191 36.2938 15.5781L38.5842 15.1027V10.8973C38.5842 9.21519 38.0204 7.78903 36.5052 7.78903C35.1662 7.78903 34.4614 8.92264 34.4614 10.4219C34.4614 11.9212 35.0252 12.6526 35.9414 12.6526C36.0823 12.6526 36.2938 12.616 36.47 12.5429C35.9414 13.9325 34.6376 14.7004 33.0167 14.7004C31.2195 14.7004 29.81 13.5668 29.81 11.6653C29.81 8.84951 32.629 7.1308 36.6461 7.1308C41.5794 7.1308 44.5394 8.70324 44.5394 13.2377C44.5394 15.5415 44.5042 18.2475 44.5042 21.3193C44.5042 23.1477 44.7861 24.2447 46.1603 24.2447C46.407 24.2447 46.6537 24.1716 46.9003 24.0985ZM38.5842 15.7609L37.5271 16.0169C35.6595 16.5654 34.7433 17.9184 34.7433 20.3319C34.7433 22.2335 35.3424 23.3671 36.4347 23.3671C37.809 23.3671 38.549 21.8678 38.5842 19.82V15.7609Z"
            fill="#22593D" />
        <path
            d="M55.3283 7.49648V21.685C55.3283 24.0985 55.4692 24.6104 56.7026 25.5246V25.5977H47.9636V25.5246C49.2322 24.6104 49.3379 24.0985 49.3379 21.685V11.5556C49.3379 9.03235 49.1265 8.48383 47.7874 7.56962V7.49648H55.3283Z"
            fill="#22593D" />
        <path
            d="M75.4742 24.0985V24.1716C74.7342 25.1955 73.1838 25.9269 71.5276 25.9269C69.0257 25.9269 67.6867 24.7567 67.2991 22.8186C67.2991 22.7454 67.2286 22.1603 67.2286 22.0506C66.7 24.3544 64.8677 25.8537 62.2248 25.8537C59.6525 25.8537 57.503 24.3544 57.503 21.3558C57.503 17.3699 61.062 16.4191 64.8677 15.5781L67.1581 15.1027V10.8973C67.1581 9.21519 66.5943 7.78903 65.0791 7.78903C63.74 7.78903 63.0353 8.92264 63.0353 10.4219C63.0353 11.9212 63.5991 12.6526 64.5153 12.6526C64.6562 12.6526 64.8677 12.616 65.0438 12.5429C64.5153 13.9325 63.2115 14.7004 61.5905 14.7004C59.7934 14.7004 58.3839 13.5668 58.3839 11.6653C58.3839 8.84951 61.2029 7.1308 65.22 7.1308C70.1533 7.1308 73.1133 8.70324 73.1133 13.2377C73.1133 15.5415 73.078 18.2475 73.078 21.3193C73.078 23.1477 73.3599 24.2447 74.7342 24.2447C74.9809 24.2447 75.2275 24.1716 75.4742 24.0985ZM67.1581 15.7609L66.101 16.0169C64.2334 16.5654 63.3172 17.9184 63.3172 20.3319C63.3172 22.2335 63.9162 23.3671 65.0086 23.3671C66.3829 23.3671 67.1229 21.8678 67.1581 19.82V15.7609Z"
            fill="#22593D" />
        <path
            d="M54.9189 2.9603C54.9189 4.40288 53.792 5.57232 52.4019 5.57232C51.0118 5.57232 49.8849 4.40288 49.8849 2.9603C49.8849 1.51773 51.0118 0.34829 52.4019 0.34829C53.792 0.34829 54.9189 1.51773 54.9189 2.9603Z"
            fill="#22593D" />
        <path
            d="M78.8301 3.48269C78.8301 1.55925 80.3326 0 82.1861 0H100.644C102.497 0 104 1.55925 104 3.48269V13.9307C104 15.8542 102.497 17.4134 100.644 17.4134H82.1861C80.3326 17.4134 78.8301 15.8542 78.8301 13.9307V3.48269Z"
            fill="url(#paint0_linear_71_800)" />
        <path
            d="M84.1997 12.4332V4.96284H85.9918L87.8845 10.0615L89.737 4.96284H91.509V12.4332H90.1699V6.79125L88.0255 12.4332H87.5926L85.4078 6.79125V12.4332H84.1997Z"
            fill="#1A432E" />
        <path
            d="M94.8983 11.3362H95.6836C97.2945 11.3362 98.0697 10.3749 98.0697 8.72414V8.64056C98.0697 7.03156 97.365 6.05989 95.6736 6.05989H94.8983V11.3362ZM93.5593 12.4332V4.96284H95.744C98.2509 4.96284 99.4692 6.44647 99.4692 8.61966V8.71369C99.4692 10.8869 98.2509 12.4332 95.7239 12.4332H93.5593Z"
            fill="#1A432E" />
        <defs>
            <linearGradient id="paint0_linear_71_800" x1="104" y1="0" x2="77.0627" y2="14.0169"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#DEE6E2" />
                <stop offset="1" stop-color="#F5F6F5" />
            </linearGradient>
        </defs>
    </svg>
</template>