<template>
    <div class="history-container">
        <div class="heading-container">
            <div class="heading">
                <div class="description">
                    <span>Vi lagrer samtaler i 24 timer. Etter dette vil dataen automatisk bli slettet.</span>
                </div>
            </div>
            <div class="link-container">
            </div>
        </div>
        <div class="content-container">
            <HistoryItem v-for="item in contexts" :key="item.id" :item="item" @click="go_item(item.id!)" :is_active="is_active(item)" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMyContextsStore } from '@/stores/contexts';
import { onMounted } from 'vue';
import HistoryItem from './HistoryItem.vue';
import { useRouter } from 'vue-router';
import { useAmplitudeStore } from '@/stores/amplitude';
import { useContextStore } from '@/stores/context';
import { storeToRefs } from 'pinia';
import type { Context } from '@/types/Context';

const router = useRouter();
const contexts_store = useMyContextsStore();
const { contexts } = storeToRefs(contexts_store);
const amplitude = useAmplitudeStore();
const context_store = useContextStore();
const { context } = storeToRefs(context_store);
const is_active = (to_check: Context) => to_check.id === context.value?.id;

onMounted(async () => {
    await contexts_store.load();
});

const go_item = (id: string) => {
    amplitude.track('History Item Clicked', { context_id: id });
    const go_context = contexts.value.find(c => c.id === id);
    const has_any_thread = go_context?.threads.length;
    const has_ttn = go_context?.agent_executions.find(ae => ae.namespace === "maiamd.mymaia.agents.ambient-journal-note");
    
    if (has_any_thread) {
        router.push({ name: 'chat', params: { context_id: id } });
    }

    else if (has_ttn) {
        router.push({ name: 'ambient', params: { context_id: id } });
    }
    else {
        router.push({ name: 'chat', params: { context_id: id } });
    }
    close();
};

const emits = defineEmits<{
    (e: 'close'): void;
}>();

const close = () => {
    emits('close');
};

</script>

<style scoped lang="scss">
.history-container {
    display: flex;
    max-width: 510px;
    width: 510px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex-shrink: 0;
    font-family: Graphik;

    .heading-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .heading {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            margin-top: 8px;



            .description {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                align-self: stretch;

                color: var(--Primary-130, #1A432E);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }

        .link-container {
            display: flex;
            align-items: center;
            gap: 4px;

            color: var(--Primary-130, #1A432E);
            font-family: Graphik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-decoration-line: underline;
        }

    }



    .content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        overflow-y: auto;
    }
}
</style>