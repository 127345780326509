import { useContextStore } from "@/stores/context";
import type { AgentExecutionResult, AgentOutput } from "@/types/Agents";
import type { ContextTitleGenerated } from "@/types/Context";
import * as signalR from "@microsoft/signalr";

const setup_connection = () => {
    const endpoint = "/hub";
    const options: signalR.IHttpConnectionOptions = {
        skipNegotiation: false,
        transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents,
        logger: signalR.LogLevel.Information
    }
    const customRetryDelays = [0, 4000, 4000, 4000, 4000];


    const connection = new signalR.HubConnectionBuilder()
        .withUrl(endpoint, options)
        .withServerTimeout(3600 * 1000)
        .withKeepAliveInterval(5 * 1000)
        .withAutomaticReconnect({ nextRetryDelayInMilliseconds: (retryContext) => customRetryDelays[retryContext.previousRetryCount] ?? 4000 })
        .withHubProtocol(new signalR.JsonHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .build();
    return connection;
}

const start_connection = async (connection: signalR.HubConnection, workspace_id: string) => {
    try {
        await connection.start();
        await join_workspace(connection, workspace_id);
        ping_server(connection);
        console.log("SignalR Connected.");
    } catch (error) {
        console.error("SignalR Connection Error: ", error);
        setTimeout(() => start_connection(connection, workspace_id), 5000);
    }
}

const join_workspace = async (connection: signalR.HubConnection, workspace_id: string) => {
    try {
        await connection.invoke("JoinWorkspace", workspace_id);
        console.log("Joined workspace: ", workspace_id);
    } catch (error) {
        console.error("Error joining workspace: ", error);
        throw error;
    }

}

const ping_server = (connection: signalR.HubConnection) => {
    setInterval(() => {
        connection.invoke("Ping")
            .catch((error) => {
                console.error("Ping error: ", error);
            });
    }, 5000);
};

let connection: signalR.HubConnection | null = null;
export const signalr = async (workspace_id: string) => {
    if (!connection) {
        connection = setup_connection();
        connection.on("ReceiveMessage", (messageType: string, message: any) => {
            switch (messageType) {
                case "AgentExecutionReady":
                    {
                        let payload = message as AgentExecutionResult;
                        if (payload.namespace === "maiamd.mymaia.agents.ambient-journal-note") {
                            payload = message as AgentExecutionResult<AgentOutput>;
                            const context_store = useContextStore();
                            context_store.on_execution_ready(payload);
                        }
                        else {
                            const context_store = useContextStore();
                            context_store.on_execution_ready(payload);
                        }
                        break;
                    }
                case "ContextTitleGenerated":
                    {
                        let payload = message as ContextTitleGenerated;
                        const context_store = useContextStore();
                        context_store.on_context_title_generated(payload.context_id, payload.name);
                        break;
                    }
                default:
                    console.error("Unhandled message type: ", messageType);
            }
        })
        await start_connection(connection, workspace_id);
    }
    return connection;
}