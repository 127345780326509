<template>
  <div class="app-container">
    <div class="sidebar-container">
      <SideBar />
    </div>
    <AlertBar message="Maia MD kan gjøre feil. All informasjon må valideres av lege." class="alert" />
    <div class="router-container">
      <div class="loading" v-if="loading">
        Laster...
      </div>
      <router-view v-slot="{ Component }" v-else :key="$route.fullPath">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/views/Home/SideBar.vue';
import AlertBar from '@/components/AlertBar.vue';

import { useMeStore } from './stores/me'
import { useMyContextsStore } from './stores/contexts'
import { useAmplitudeStore } from "@/stores/amplitude"
import { useConfigStore } from './stores/configuration'
import { ref } from 'vue'
import { signalr } from '@/utils/signalr'
import { storeToRefs } from 'pinia'

const me_store = useMeStore();
const contexts_store = useMyContextsStore();
const amplitude_store = useAmplitudeStore();
const config_store = useConfigStore();
const loading = ref(true);


const initialize = async () => {
  try {
    await config_store.load();
    amplitude_store.init();
    await me_store.fetch_me();
    const {me} = storeToRefs(me_store);
    await signalr(me.value?.default_workspace_id!);
    await contexts_store.load();
  }
  finally {
    loading.value = false;
  }
};

initialize();
</script>

<style scoped lang="scss">
.app-container {
  width: 100%;
  display: grid;
  grid-template-areas:
    "sidebar alert"
    "sidebar router";

  grid-template-columns: 260px 1fr;
  grid-template-rows: auto 1fr;
}

.alert {
  grid-area: alert;
  display: flex;
  width: calc(100vw - 300px);
  padding: 8px 32px 8px 40px;
}

.router-container {
  grid-area: router;
  display: flex;
  flex-direction: column;
}

.sidebar-container {
  grid-area: sidebar;
  position: sticky;
  top: 0;
  height: 100vh;
  display: inline-flex;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  color: var(--Primary-160, #0E2418);
  background-color: var(--Primary-20, #F5F9F8);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  opacity: 0.9;
  font-family: Graphik;
  font-style: normal;
}
</style>
