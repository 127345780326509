<template>
    <Teleport to="body">
        <div class="box" ref="box" v-if="visible" :class="props.visible ? 'slide-in' : 'slide-out'">
            <div class="heading">
                <div class="title">
                    <span>{{ props.title }}</span>
                    <DefaultButton @click="close()" size="xs" styling="Inverted">
                        <RemoveIcon />
                    </DefaultButton>
                </div>
            </div>
            <slot name="content">
            </slot>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import DefaultButton from '@/components/DefaultButton.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import { ref } from 'vue';
import { onClickOutside } from '@/utils/clickoutside';


const props = defineProps<{
    visible: boolean,
    title: string
}>();


const box = ref<HTMLElement | null>(null);
const emits = defineEmits<{
    (e: 'close'): void;
}>();

const close = () => {
    emits('close');
}


onClickOutside(box, () => {
    close();
});


</script>

<style scoped lang="scss">
.box {
    position: fixed;
    top: 0px;
    left: 250px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    height: 100vh;
    background: rgba(244, 247, 245, 0.80);
    box-shadow: 12px 0px 12px -10px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    padding: 32px;
}

.box .heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;



    .title {
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        justify-content: space-between;
        color: var(--Primary-130, #1A432E);
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .description {
        display: flex;
        padding-right: 32px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        color: var(--Primary-130, #1A432E);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
}



.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}
</style>