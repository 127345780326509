<template>
    <button class="" :class="[styling, size, state, theme]" :disabled="loading"
        @mousedown=toggle_pressed>
        <slot v-if="iconPosition == 'Left'" name="icon" />
        <slot />
        <slot v-if="iconPosition == 'Right'" name="icon" />
    </button>
</template>
<script setup lang="ts">
import { ref, type PropType } from 'vue'
export type AllowedThemes = 'Primary' | 'Secondary' | 'Error' | 'Tertiary' | 'Stop'
export type AllowedStyles = 'Filled' | 'Inverted'
export type AllowedSize = 'xs' | 'sm' | 'md' | 'lg'
export type AllowedState = 'Default' | 'Hover' | 'Focus' | 'Pressed' | 'Disabled'
export type IconPosition = "Left" | "Right"
export interface DefaultButtonProps {
    theme: AllowedThemes,
    styling: AllowedStyles,
    size: AllowedSize,
    state: AllowedState,
    loading: boolean,
    iconPosition: IconPosition
}
const props = defineProps({
    theme: {
        type: String as PropType<AllowedThemes>,
        default: 'Primary',
        required: false,
    },
    styling: {
        type: String as PropType<AllowedStyles>,
        default: 'Filled',
        required: false,
    },
    size: {
        type: String as PropType<AllowedSize>,
        default: 'md',
        required: false,
    },
    state: {
        type: String as PropType<AllowedState>,
        default: 'Default',
        required: false,
    },
    loading: {
        type: Boolean,
        default: false,
        required: false,
    },
    iconPosition: {
        type: String as PropType<IconPosition>,
        default: 'Right',
        required: false,
    }
}) as DefaultButtonProps


const initial_state = props.state;
const current_state = ref(props.state);
const toggle_pressed = () => {
    if (current_state.value == initial_state) {
        current_state.value = 'Pressed';
    } else {
        current_state.value = initial_state;
    }
}


</script>

<style scoped lang="scss">
button {
    cursor: pointer;
    display: inline-flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    color: var(--text-white, #FFF);
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;



    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 4px;
    border-width: 0px;
    --hover-background: var(--Primary-130, #1A432E);
    --hover-shadow: 0px 6px 15px -6px rgba(34, 89, 61, 0.20);


    &.Primary {
        border-radius: 4px;
        background: var(--primary-100, #22593D);
    }

    &.Error {
        background: var(--secondary-red-100, #A10404);
    }

    &.Secondary {
        background: var(--secondary-purple-100, #7E4590);

        &:hover{
            background: var(--Secondary-Purple-130, #61356E);
        }

        &:active {
            background: var(--Secondary-Purple-100, #7E4590);
        }
    }

    &.Tertiary {
        background: var(--Primary-5, #F4F7F5);
        color: var(--Primary-160, #0E2418);
    }

    &.Inverted {
        background: var(--Primary-5, #F4F7F5);
        color: #151515;
        --hover-background: var(--Primary-15, #DEE6E2);
        --hover-shadow: 0px 6px 15px -6px rgba(34, 89, 61, 0.20);
    }
    &.Inverted.Secondary {
        background: var(--Secondary-Purple-5, #F5EEF7);
        color: var(--Secondary-Purple-160, #4B2956);
    }

    &.Stop {
        background: var(--Primary-160, #0E2418);
    }



    &.sm {
        padding: 10px;
        gap: 8px;
        font-size: 13px;
    }

    &.xs {
        display: inline-flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &:active,
    &.Pressed {
        border-radius: 4px;
        background: var(--primary-30, #BDCDC5);
    }

    &.Disabled {
        border-radius: 4px;
        background: var(--greyscale-100, #DDD);
        color: var(--text-caption, #595959);
        pointer-events: none;

        &:hover {
            background: var(--greyscale-100, #DDD);
            box-shadow: none;
        }
    }

    &:hover {
        background: var(--hover-background);
        box-shadow: var(--hover-shadow);
    }
}
</style>