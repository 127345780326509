import type { Context } from "@/types/Context";
import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import axios from "@/utils/axios";
import { useMeStore } from "./me";

export const useMyContextsStore = defineStore('MyContextsStore', () => {
    const me_store = useMeStore();

    const contexts = ref<Context[]>([]);
    const { me } = storeToRefs(me_store);

    const load = async () => {
        const workspace_id = me.value?.default_workspace_id;
        const url = "/api/v1/workspaces/" + workspace_id + "/contexts";
        const response = await axios.get(url);
        contexts.value = response.data.data;
    };

    return {
        contexts,
        load
    };
});