import { defineStore } from "pinia";
import axios from "@/utils/axios";
import { computed, ref } from "vue";
import { type Ambience } from "@/types/Ambience";
import { MicrophoneAudioTranscriber, type MicrophoneAudioTranscriberOptions } from "@/audio/MicrophoneAudioTranscriber";

export const useAmbienceStore = defineStore("ambience", () => {
    const context_id = ref<string | null>(null);
    const microphone = ref<MediaDeviceInfo | null>(null);
    const ambience = ref<Ambience | null>(null);
    const is_recording = ref(false);
    const time_left = ref<number | null>(null);
    const ambience_id = computed(() => ambience.value?.id);
    const preferred_mic = computed(() => microphone.value?.deviceId || localStorage.getItem('preferred_microphone_id'));
    const model = computed(() => ambience.value?.model);
    const recorder = ref<MicrophoneAudioTranscriber | null>(null);
    const duration_millis = computed(() => {
        if (!recorder.value) return 0;
        return recorder.value.duration * 1000;
    });


    const setup = async (c_id: string, device?: MediaDeviceInfo) => {
        context_id.value = c_id;
        if (!device) {
            const preferred_device_id = localStorage.getItem('preferred_microphone_id');
            if (preferred_device_id) {
                device = (await navigator.mediaDevices.enumerateDevices()).find((d) => d.deviceId === preferred_device_id);
            }
            if (!device) {
                throw new Error("No microphone device specified");
            }
        }
        else {
            localStorage.setItem('preferred_microphone_id', device.deviceId);
        }

        microphone.value = device;
        await create_ambient(context_id.value);
    }

    const start = async (on_data_cb: (data: Int16Array) => void) => {
        if (!context_id.value) {
            throw new Error("No context specified");
        }

        if (!microphone.value) {
            throw new Error("No microphone device specified");
        }

        if (!ambience.value) {
            throw new Error("No ambience specified");
        }

        if (is_recording.value) {
            throw new Error("Already recording");
        }


        const options: MicrophoneAudioTranscriberOptions = {
            microphone: microphone.value!,
            recognition_language: 'nb-NO',
            on_recognized(text) {
                if (text && text.length > 0) {
                    push_transcription_segment(text);
                }
            },
            on_data(data) {
                on_data_cb(data);
            },
            on_recognizing(text) {
                // do nothing
            },
            ambience_id: ambience_id.value!,
            context_id: context_id.value!,
            region: model.value?.region!,
        }

        recorder.value = new MicrophoneAudioTranscriber(options);
        is_recording.value = true;
        await recorder.value.start();
    }

    const stop = async () => {
        if (!recorder.value) {
            throw new Error("No recorder");
        }

        is_recording.value = false;
        await recorder.value.stop();
    }

    const create_ambient = async (context_id: string) => {
        const url = `/api/v2/contexts/${context_id}/ambients`;
        const response = await axios.post(url);
        ambience.value = response.data;
    }


    const push_transcription_segment = async (segment: string) => {
        const endpoint = `/api/v2/ambients/${ambience_id.value}/segments`;
        const data = {
            Segments: [segment]
        };
        await axios.post(endpoint, data);
    };

    return {
        is_recording,
        time_left,
        ambience_id,
        setup,
        preferred_mic,
        model,
        start,
        stop,
        duration_millis
    }
});