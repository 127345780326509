<template>
    <div class="button" :class="[state]">
        <div class="title-wrapper">
            <div class="icon">
                <slot name="icon" />
            </div>
            <div class="title">
                <span>{{ title }}</span>
            </div>
        </div>
        <slot name="button" v-if="show_button">
           
        </slot>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'




const props = defineProps({
    is_active: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        required: true
    },
    show_button: {
        type: Boolean,
        default: false
    },
    button_text: {
        type: String,
        default: 'Start'
    }
})

const state = computed(() => props.is_active ? 'Pressed' : 'Default');


</script>

<style scoped lang="scss">
.button {
    display: flex;
    width: 220px;
    padding: 8px 8px 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid transparent;



    .title-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;


        .icon {
            width: 24px;
            height: 24px;
        }

        .title {
            flex: 1 0 0;
            color: var(--Text-Caption, #595959);
            font-family: Graphik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;

            user-select: none;
            pointer-events: none;
        }
    }

   

    &:hover {
        border: 2px solid var(--Primary-30, #BDCDC5);
    }

    &:active,
    &.Pressed {
        background: var(--Primary-15, #DEE6E2);
    }
}
</style>