import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import axios from "@/utils/axios";
import { useContextStore } from "@/stores/context";
import type { AgentExecutionResult, AgentOutput } from "@/types/Agents";

export const useAmbientJournalNoteStore = defineStore("agents.ambient_journal_note", () => {
    const generating = ref(false);
    const ambience_id = ref<string | null>(null);
    const context_store = useContextStore();
    const { context } = storeToRefs(context_store);
    const endpoint = computed(() => `/api/v1/agentexecutions/ambient-journal-note`);
    const output = computed(() => context.value?.agent_executions.find((execution) => execution.namespace === "maiamd.mymaia.agents.ambient-journal-note") as AgentExecutionResult<AgentOutput> | undefined);
    


    watch(context, () => {
        ambience_id.value = null;
        generating.value = false;
    });



    const generate = async (ambience_identifier: string, prompt_override: string | null) => {
        if (output.value) {
            console.error("Ambient Journal Note already exists");
            return;
        }
        ambience_id.value = ambience_identifier;
        generating.value = true;
        try {
            const payload = { ambient_id: ambience_id.value, prompt_override: prompt_override};
            const response = await axios.post(endpoint.value, payload);
            context_store.on_execution_ready(response.data);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            generating.value = false;
        }
    };

    const replace_data = async (agent_execution_id: string, data: AgentOutput) => {
        const command = {
            replacement_data: data
        }
        const url = `/api/v1/agentexecutions/${agent_execution_id}/update`;
        await axios.post(url, command);
    }

    const del = async () => {
        if (!output.value) {
            console.error("Ambient Journal Note does not exist");
            return;
        }
        const url = `/api/v1/agentexecutions/${output.value.id}`;
        await axios.delete(url);
    }

    return {
        generating,
        output,
        generate,
        replace_data,
        delete_output: del
    }
});