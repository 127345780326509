import './assets/main.css'
import router from './router'
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";



const app = createApp(App);
const pinia = createPinia();

app.use(pinia);

const hostname = window.location.hostname;
let environment;

// keep these for now, match the CICD strategy matrix for names, will make sentry link to the release
if (hostname === "app.staging.maiamd.ai") {
    environment = "staging";
} else if (hostname === "app.maiamd.ai") {
    environment = "prod";
} else {
    environment = "development";
}

console.log("Detected environment:", environment);


Sentry.init({
    app,
    dsn: "https://d03c537732852b78d9a37ada820f374f@o4508437952921600.ingest.de.sentry.io/4508437960065104",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 0.5,
    tracePropagationTargets: ["localhost", "https://app.maiamd.staging.ai", "https://app.maiamd.ai"],
    sendDefaultPii: false,
    environment: environment
});




app.use(router);
app.mount('#app');


