<template>
    <div class="item" @click="click()" :class="{ active: is_active }">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" v-if="is_active">
            <path
                d="M10.9963 8.48767C10.9963 8.72041 10.8966 8.91989 10.7303 9.05288L7.00663 12.3444C6.74065 12.5771 6.34168 12.5438 6.14219 12.2779C5.94271 12.0119 5.94271 11.6462 6.20869 11.4134L9.46693 8.55417C9.50018 8.52092 9.50018 8.48767 9.46693 8.42118L6.20869 5.56191C5.94271 5.32918 5.94271 4.93021 6.17544 4.69748C6.40817 4.46475 6.77389 4.4315 7.03987 4.63098L10.7636 7.88922C10.8966 8.05546 10.9963 8.25494 10.9963 8.48767Z"
                fill="#22593D" />
        </svg>
        <div class="actual">
            <span class="title">
                {{ item.name }}
            </span>
            <span class="timestamp">{{ format_date(item.created_at!) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Context } from '@/types/Context';
import { DateTime } from 'luxon';

const props = defineProps<{
    item: Context,
    is_active: boolean
}>();


const format_date = (date: string) => {
    return DateTime.fromISO(date).toLocal().setLocale('nb').toFormat("HH:mm:ss – dd.MM.yyyy");
}

const emits = defineEmits<{
    (e: 'click', item: Context): void;
}>();

const click = () => {
    emits('click', props.item);
}


</script>

<style scoped lang="scss">
.item {
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;
    font-family: Graphik;


    .actual {
        display: flex;
        border-radius: 4px;
        display: inline-flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        cursor: pointer;

        font-family: Graphik;
        width: 100%;


        .title {
            height: 22px;
            align-self: stretch;
            overflow: hidden;
            color: var(--Text-Title, #151515);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

        }

        .timestamp {
            color: var(--Text-Caption, #595959);
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }



    &:hover {
        background: var(--Primary-15, #DEE6E2);
    }

    &:active,
    &.Pressed {
        background: var(--Primary-30, #BDCDC5);
    }

    &.active {
        background: var(--Primary-30, #BDCDC5);
        border-radius: 2px;
        border: 2px solid var(--Primary-130, #1A432E);
        background: var(--Primary-15, #DEE6E2);
    }
}
</style>
