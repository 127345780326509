<template>
    <div class="alert-bar" :style="{ background: props.background || '#FCF7DF' }">
        <div class="inner">
            <slot name="icon">
                <AlertIcon />
            </slot>
            <slot name="message">
                <span> {{ props.message }}</span>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import AlertIcon from '@/components/icons/AlertIcon.vue';
const props = defineProps<{
    message: string;
    background?: string;
}>();

</script>


<style lang="scss" scoped>
.alert-bar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    background: var(--Secondary-Yellow-15, #FCF7DF);
    padding: 24px 16px;

    color: var(--Secondary-Yellow-130, #534709);
    font-family: Graphik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;



    .inner {
        align-items: center;
        display: flex;
        gap: 12px;
    }
}
</style>