import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "@/utils/axios";

export type ChatPromptSample = string;
export interface ApplicationConfiguration {
    ask_samples: ChatPromptSample[];
    amplitude_api_key: string;
    version_info: {
        numbered: string;
        name: string;
    }
}

export const useConfigStore = defineStore("config_store", () => {
    const configuration = ref<ApplicationConfiguration | null>(null);
    const load = async () => {
        const endpoint = "/api/v1/configuration";
        const response = await axios.get(endpoint);
        configuration.value = response.data;
    };

    return {
        configuration,
        load
    }
});