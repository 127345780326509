export const read_sse = async <T>(
    stream_reader: ReadableStreamDefaultReader<Uint8Array>,
    signal: AbortSignal,
    callback: (element: T) => void): Promise<T[]> => {
    const decoder = new TextDecoder();
    let buffer = '';
    const elements: T[] = [];

    signal.addEventListener('abort', () => {
        if(stream_reader){
            stream_reader.cancel();
        }
    });

    try {
        let reading = true;
        while (reading) {
            const { done, value } = await stream_reader.read();
            if (done) {
                reading = false;
                break;
            }

            buffer += decoder.decode(value, { stream: true });
            buffer = processBuffer(buffer, callback, elements);
        }
        processRemainingBuffer(buffer, callback, elements);
    } catch (error) {
        console.error("Stream reading error:", error);
        throw error;
    } finally {
        stream_reader.releaseLock();
    }
    return elements;
};

const processBuffer = <T>(buffer: string, callback: (element: T) => void, elements: T[]): string => {
    const parts = buffer.split('\n\n');
    for (let i = 0; i < parts.length - 1; i++) {
        processEvent(parts[i], callback, elements);
    }
    return parts[parts.length - 1];
};

const processEvent = <T>(eventString: string, callback: (element: T) => void, elements: T[]): void => {
    try {
        const stripped = eventString.replace('data: ', '');
        const event: T = JSON.parse(stripped);
        elements.push(event);
        callback(event);
    } catch (error) {
        console.error("Event processing error:", error);
    }
};

const processRemainingBuffer = <T>(buffer: string, callback: (element: T) => void, elements: T[]): void => {
    if (buffer.trim().length > 0) {
        processEvent(buffer, callback, elements);
    }
};